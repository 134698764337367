import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from 'gatsby-plugin-firebase';
import slugify from 'slugify';

const EditProfile = ({ }) => {
  const [error, setError] = useState('');
  const [firebase, setFirebase] = useState();
  const [userName, setUserName] = useState('');

  useFirebase(
    firebase => {
      setFirebase(firebase);

      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const users = await firebase.firestore().collection('users');
          const thisUser = await users.doc(user.uid).get();
          if (thisUser.exists) {
            setUserName(thisUser.data().userName);
          }
        }
      });
    },
    [firebase]
  );

  const handleSubmit = async e => {
    e.preventDefault();

    const db = firebase.firestore();
    const userId = firebase.auth().currentUser.uid;
    const slug = slugify(userName, { lower: true });

    const userRef = db.collection('users').doc(userId);
    const userNameRef = db.collection('userNames').doc(slug);

    db.runTransaction(transaction => {
      return transaction.get(userRef).then(user => {
        if (!user.exists) {
          transaction.set(
            userRef,
            {
              userName,
              slug,
            },
            { merge: true }
          );
          transaction.set(userNameRef, {
            userId,
          });
        } else {
          const currentUserName = user.data().slug;
          const currentUserNameRef = db
            .collection('userNames')
            .doc(currentUserName);
          // remove the old username
          transaction.delete(currentUserNameRef);
          transaction.set(
            userRef,
            {
              userName,
              slug,
            },
            { merge: true }
          );
          transaction.set(userNameRef, {
            userId,
          });
        }
        return true;
      });
    })
      .then(function(newUser) {
        console.log('User success? ', newUser);
      })
      .catch(function(err) {
        console.error(err);
        setError('Unable to update username');
      });
  };

  return (
      <>
        <h1>Edit Profile</h1>
        <p>Please add a username</p>
        {error && <p>{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            onChange={e => setUserName(e.target.value)}
            value={userName}
          />
          <input type="submit" value="Submit" />
        </form>
      </>
  );
};

EditProfile.propTypes = {
  user: PropTypes.shape({
    userName: PropTypes.string,
  }),
};

export default EditProfile;
