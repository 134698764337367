import React from 'react';

import Layout from '../components/furniture/Layout/Layout';
import generateTitle from '../utils/generateTitle';
import EditProfile from '../components/profile/EditProfile/EditProfile';

const EditProfilePage = ({ location, user }) => {
  const title = generateTitle('Edit Profile', 'Profile');

  return (
    <Layout location={location} pageTitle={title}>
      <EditProfile />
    </Layout>
  );
};

export default EditProfilePage;
